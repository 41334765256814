<template>
    <div class="coordination-container">
       
        <ListData viewerKey='myCreate'></ListData>

       
    </div>
</template>

<script>
import ListData from './components/List.vue'

export default {
	components:{
		ListData
	},
	data() {
		return {
		};
	},
	computed: { },
	methods: {
       
	},
	created() {},
	mounted() {},
};
</script>

<style lang='less' scoped>
@import "../../styles/index";
.coordination-container {
    .create-btn{
        margin-bottom: 5px;
    }
}

</style>